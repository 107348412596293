<template>
  <footer>
    <p>© 2025 恐龙岛 | 开发者: GIANT BEAST STUDIO</p>
    <div class="box"></div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {};
  },

  mounted() {},
  methods: {},
};
</script>
<style lang='scss' scoped>
footer {
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  text-align: center;
  padding: 30px 50px;
  position: relative;
  z-index: 1;
  p {
    margin-bottom: 10px;
    font-size: 1em;
  }
  .box{
    height: 10%;
    background: #fff;
  }
}
</style>