<template>
  <section id="Home">
    <video autoplay loop playsinline muted>
      <source src="@/assets/video/恐龙岛.webm" type="video/webm" />
      <source src="@/assets/video/恐龙岛.mp4" type="video/mp4" />
      您的浏览器暂不支持 HTML5 视频标签
    </video>
    <h1>一款恐龙类型的生存冒险游戏</h1>
    <p>探索未知，生存下去，成为岛屿的主宰。</p>
    <div class="Homebtn">
      <a href="https://giantbeast-asset.konglongzd.top/archive/Giantbeast/Bot.Live/Default/Windows/GiantBeastIsland.exe " target="_blank">
        <button class="btn btn2">官网下载</button>
      </a>
      <!-- <button class="btn btn1" @click="showVideo()">观看视频</button> -->
      <a href="https://store.steampowered.com/app/3139890/_/ " target="_blank">
        <button class="btn btn2">Steam抢先版下载</button>
      </a>
    </div>
    <div class="scrollBtn" @click="scrollToAbout()">↓</div>
  </section>
</template>

<script>
export default {
  name: "Main",
  data() {
    return {};
  },
  mounted() {},
  methods: {
    showVideo() {
      this.$emit("openVideo", true);
    },
    scrollToAbout() {
      document.documentElement.scrollTop = 948;
    },
  },
};
</script>
<style lang="scss" scoped>
#Home {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 20px;
  z-index: 1;
  overflow: hidden;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }
  h1 {
    font-size: 4em;
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    margin-bottom: 20px;
    z-index: 1;
  }
  p {
    font-size: 1.5em;
    color: #ddd;
    margin-bottom: 30px;
    max-width: 800px;
    z-index: 1;
  }

  @media (max-width: 1200px) {
    h1 {
      font-size: 3.5em;
    }
    p {
      font-size: 1.3em;
    }
  }
  @media (max-width: 768px) {
    h1 {
      font-size: 2.5em;
    }
    p {
      font-size: 1.2em;
    }
  }
  @media (max-width: 480px) {
    h1 {
      font-size: 2em;
    }
    p {
      font-size: 1em;
    }
  }

  .Homebtn {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 1;
  }
}
//中央点击按钮
.btn {
  display: inline-block;
  background: linear-gradient(90deg, #f6d365, #fda085);
  color: rgba($color: #000000, $alpha: 0.8);
  // color: rgba($color: #fff, $alpha: 1);
  font-weight: 500;
  padding: 15px 30px;
  font-size: 1.2em;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 8px 25px rgba(253, 160, 133, 0.8);
  transition: transform 0.3s, box-shadow 0.3s, background 0.3s;
  text-align: center;
  position: relative;
  overflow: hidden;
  letter-spacing: 0.025em;
}

.btn::after {
  content: "";
  position: absolute;
  // background: linear-gradient(90deg, #f6d365, #fda085);
  // background: rgba(#000000, 0.1);
  background: rgba(#fff, 0.1);
  // background: #fff;
  transition: all 0.5s;
}
// .btn1::after {
//   top: 0%;
//   right: 100%;
//   width: 100%;
//   height: 100%;
// }
// .btn2::after {
//   top: 0%;
//   left: 100%;
//   width: 100%;
//   height: 100%;
// }
.btn:hover {
  transform: scale(1.07);
  // box-shadow: 0 12px 30px rgba(240, 84, 84, 1);
  // background: linear-gradient(90deg, #d94343, #f05454);
  // background: #000000;
  color: #fff;
  font-weight: 700;
}
// .btn1:hover::after {
//   top: 0%;
//   right: 0%;
//   width: 100%;
//   height: 100%;
// }
// .btn2:hover::after {
//   top: 0%;
//   left: 0%;
//   width: 100%;
//   height: 100%;
// }
//向下滚动箭头
.scrollBtn {
  position: absolute;
  bottom: 20px;
  font-size: 2em;
  animation: bounce 2s infinite;
  cursor: pointer;
  color: #f6d365;
  z-index: 1;
}
//箭头滚动动画
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
</style>
