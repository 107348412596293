<template>
  <section id="About" style="background-size: cover;background-repeat: no-repeat;">
    <h2>游戏介绍</h2>
    <p>
      这是一款非常有趣的恐龙类型的生存冒险游戏。玩家扮演的恐龙出生在一个充满未知与危险的侏罗纪岛屿，这里生活着各种形态各异的恐龙。玩家需要在这个危机四伏的原始世界中生存下去，并探索岛屿的秘密。
    </p>
    <p>
      在这座岛屿中，想要提高生存率，那么最好进入一个团队，每个团队都有自己的领地，可以放心地在自己团队的领地培养恐龙。
    </p>
    <p>游戏中可以按WASD进行移动，同时可以按住SHIFT+WASD进行奔跑。</p>
    <p>
      游戏中可以使用鼠标进行攻击，一般使用鼠标左键攻击，某些恐龙有两种攻击方式，第二种攻击方式是右键攻击。在与其他恐龙战斗时，可以根据不同的恐龙采用不同的战术，可以选择不同的攻击方式、走位方式、地形等。
    </p>
    <p>击杀恐龙、吃肉、喝水能获得经验，经验满之后可以进化。</p>
  </section>
</template>

<script>
export default {
  name: "About",
  data() {
    return {};
  },
  mounted() {

  },
};
</script>
<style lang="scss" scoped>
#About {
  padding: 150px 50px;
  background: url(@/assets/img/about.jpg);
  background-repeat: no-repeat;
  background-position: 1px 1px;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
#About h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #f6d365;
}
#About p {
    max-width: 800px;
    margin-bottom: 15px;
    line-height: 1.6;
    font-size: 1.1em;
    color: #ddd;
}
</style>
