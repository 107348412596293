<template>
  <header>
    <div class="logo-container">
      <a href="#Home">
        <img src="@/assets/img/logo.jpg" alt="" />
      </a>
      <a href="#Home" class="title"> 恐龙岛(巨兽岛) </a>
    </div>
    <nav>
      <a href="#Home" :class="{ active: current == 1 }">
        首页
        <span class="underline"> </span>
      </a>
      <a href="#About" :class="{ active: current == 2 }">
        关于游戏
        <span class="underline"> </span>
      </a>
      <a href="#Gallery" :class="{ active: current == 3 }">
        内容展示
        <span class="underline"> </span>
      </a>
      <!-- <a href="#Promotion" :class="{ active: current == 4 }">
        福利活动
        <span class="underline"> </span>
      </a> -->
      <a href="https://store.steampowered.com/app/3139890/_/" target="_blank">
        下载游戏
        <span class="underline"> </span>
      </a>
    </nav>
  </header>
</template>

<script>
export default {
  name: "TabHeader",
  data() {
    return {};
  },
  props: {
    current: {
      type: Number,
      default: 1,
    },
  },
  mounted() {
    // window.addEventListener("scroll", this.scrollToTop);
  },
};
</script>
<style lang="scss" scoped>
header {
  position: fixed;
  top: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 50px;
  z-index: 1000;
  transition: background 0.3s;
  .logo-container {
    display: flex;
    align-items: center;
    gap: 10px;
    img {
      height: 50px;
      width: auto;
      // animation: logoBreath 1.5s infinite;
      animation: logoTran 1.5s infinite;
      // animation: logoRotate linear 3s infinite;
    }
    .title {
      font-size: 2.5em;
      // color: #f05454;
      color: #f6d365;
      font-weight: bold;
      text-decoration: none;
      text-shadow: 0 0 10px rgba(253, 160, 133, 0.8);
    }
  }
}
nav {
  display: flex;
  align-items: center;
  gap: 30px;
  position: relative;
  a {
    color: #fff;
    text-decoration: none;
    font-size: 1.2em;
    position: relative;
    transition: color 0.3s;
  }
  a::after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    background: #fda085;
    left: 0;
    bottom: -5px;
    transition: width 0.3s;
  }
  .underline {
    position: absolute;
    bottom: -5px;
    left: 0;
    height: 2px;
    background: #fda085;
    transition: transform 0.3s ease, width 0.3s ease;
    z-index: -1;
    width: 100%;
    transform: scaleX(0);
  }
  .active .underline {
    transform: scaleX(1);
  }
  a:hover {
    color: #fda085;
  }
  a:hover::after {
    width: 100%;
  }
}
@media (max-width: 768px) {
  nav {
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
  }
}
@keyframes logoTran {
  0% {
    transform: translateX(0px);
    filter: brightness(1);
  }
  25% {
    transform: translateX(20px);
    filter: brightness(1.2);
  }
    100% {
    transform: translateX(0px);
    filter: brightness(1);
  }
}
@keyframes logoBreath {
  0% {
    transform: rotateZ(0deg);
    filter: brightness(1);
  }
  25% {
    transform: rotateZ(-10deg);
    filter: brightness(1.2);
  }
  50% {
    transform: rotateZ(0deg);
    filter: brightness(1.2);
  }
  75% {
    transform: rotateZ(10deg);
    filter: brightness(1.2);
  }
}
@keyframes logoRotate {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
@media (max-width: 1200px) {
  header {
    padding: 15px 30px;
  }
}
@media (max-width: 768px) {
  header {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 20px;
  }
}
</style>
