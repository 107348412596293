<template>
  <div class="home">
    <TabHeader :current="current"></TabHeader>
    <Main @openVideo="openVideoModal"></Main>
    <main>
      <About></About>
      <Gallery @openImage="openImgModal"></Gallery>
      <!-- <Promotion @copyCode="copyIt"></Promotion> -->
      <Footer></Footer>
    </main>
    <!-- 复制弹窗 -->
    <!-- <div class="copyMessage" :class="{ show: show }">
      礼包码已复制到剪贴板！
    </div> -->
    <!-- 视频弹窗 -->
    <div id="videoModal" class="modal" v-show="videoShow">
      <div class="modal-content">
        <span class="close" @click="closeVideoModal()">×</span>
        <video class="modal-video" controls>
          <source src="@/assets/video/恐龙岛.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
    <!-- 图片弹窗 -->
    <div id="ImgModal" class="modal" v-show="imgShow">
      <div class="modal-content">
        <span class="close" @click="closeImgModal()">×</span>
        <img :src="Src" alt="" class="modal-image" />
      </div>
    </div>
  </div>
</template>

<script>
import TabHeader from "@/components/TabHeader.vue";
import Main from "@/components/Main.vue";
import About from "@/components/About.vue";
import Gallery from "@/components/Gallery.vue";
import Promotion from "@/components/Promotion.vue";
import Footer from "@/components/Footer.vue";
import { throttle } from "@/utils/tool.js";

export default {
  components: { TabHeader, Main, About, Gallery, Promotion, Footer },
  name: "Home",
  data() {
    return {
      show: false,
      timer: null,
      videoShow: false,
      imgShow: false,
      Src: null,
      scrollTop: 0,
      current: 0,
    };
  },
  methods: {
    //实现复制
    copyIt(code) {
      var textarea = document.createElement("textarea");
      textarea.value = code;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      this.show = true;
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.show = false;
      }, 2000);
    },
    //打开
    openVideoModal(val) {
      this.videoShow = val;
    },
    //关闭
    closeVideoModal() {
      this.videoShow = false;
    },
    closeImgModal() {
      this.imgShow = false;
    },
    //打开图片弹窗
    openImgModal(val) {
      this.imgShow = true;
      this.Src = val;
    },
    //监听滚动
    scrollToTop() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // console.log("top", this.scrollTop);
    },
  },
  mounted() {
    window.addEventListener("scroll", throttle(this.scrollToTop, 200), true);
  },
  watch: {
    scrollTop:{
      handler(newValue,oldValue){
          if(newValue<1000){
            this.current=1
          }else if(938<newValue&&newValue<1026){
            this.current=2
          }else if(newValue>1026){
            this.current=3
          }
      },
      immediate: true
    }
  },
};
</script>

<style lang="scss" scoped>
main {
  z-index: 1;
}

@media (max-width: 1200px) {
  #About,
  #Gallery,
  #Promotion {
    padding: 80px 30px;
  }
}
@media (max-width: 768px) {
    #About, #Gallery, #Promotion {
        padding: 60px 20px;
    }
}
.copyMessage {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  color: #f05454;
  padding: 10px 20px;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s, transform 0.5s;
  font-size: 0.8em;
  z-index: 3000;
}
//复制框
.show {
  opacity: 1;
}
.modal {
  display: flex;
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  .modal-content {
    position: relative;
    background-color: #000;
    padding: 0;
    border: none;
    width: 100%;
    max-width: 800px;
    border-radius: 10px;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.5);
    .close {
      position: absolute;
      top: -40px;
      right: 0;
      color: #fff;
      font-size: 35px;
      font-weight: bold;
      cursor: pointer;
      z-index: 2100;
    }
    .modal-video {
      width: 100%;
      height: auto;
      border-radius: 10px;
    }
    .modal-image {
      width: 100%;
      height: auto;
      border-radius: 10px;
    }
  }
}
</style>
