<template>
  <section
    id="Gallery"
    style="
      background-size: cover;
      background-position: center bottom;
      background-repeat: no-repeat;
    "
  >
   <!-- padding: 100px 50px; -->
    <h2>游戏截图</h2>
    <!-- <div class="GalleryGrid">
      <img
        :src="item.url"
        :alt="item.alt"
        v-for="(item, index) in imgList"
        :key="item.id"
        @click="showPic(index)"
      />
    </div> -->
    <div class="swiper">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in imgList"
            :key="index"
          >
            <div class="pic" @click="showPic(index)">
              <img class="img" :src="item.url" alt="" />
            </div>
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>
        <!-- 如果需要导航按钮 -->
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
        <div class="swiper-scrollbar"></div>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
export default {
  name: "Gallery",
  data() {
    return {
      swiperInstance: null,
      imgList: [
        {
          id: 1,
          url: require("@/assets/img/ex1.jpg"),
          alt: "img1",
        },
        {
          id: 2,
          url: require("@/assets/img/ex2.jpg"),
          alt: "img2",
        },
        {
          id: 3,
          url: require("@/assets/img/ex3.jpg"),
          alt: "img3",
        },
        {
          id: 4,
          url: require("@/assets/img/ex4.jpg"),
          alt: "img4",
        },
        {
          id: 5,
          url: require("@/assets/img/ex5.jpg"),
          alt: "img4",
        },
        {
          id: 6,
          url: require("@/assets/img/ex6.jpg"),
          alt: "img4",
        },
      ],
    };
  },
  mounted() {
    var swiperInstance = new Swiper(".swiper-container", {
      // config...
      loop: true,
      autoplay: {
        delay: 2000, //1秒切换一次
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      updateOnImagesReady: true,
      slidesPerView: 3,
      spaceBetween: 20,
      //spaceBetween : '10%',按container的百分比

      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  },
  methods: {
    showPic(index) {
      this.$emit("openImage", this.imgList[index].url);
    },
  },
};
</script>
<style lang="scss" >
#Gallery {
  padding: 100px 50px;
  background: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-image: url("@/assets/img/gallery.jpg");
  h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #f6d365;
  }
}
.swiper {
  width: 95%;
  .swiper-container {
    width: 100%;
    img {
      width: 100%;
      height: auto;
      cursor: pointer;
      border-radius: 10px;
    }
  }
}
.swiper-button-next,
.swiper-button-prev {
  color: #f6d365;
}
.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 3em;
}
.swiper-pagination-bullet {
  background: #fff;
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background: #f6d365;
}
.swiper-scrollbar {
  background: rgba($color: #f6d365, $alpha: 0.1);
}
.GalleryGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 1200px;
  max-width: 1200px;
  img {
    width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: 10px;
    transition: transform 0.3s;
  }
  img:hover {
    transform: scale(1.05);
  }
}
@media (max-width: 1200px) {
  .GalleryGrid {
    width: 1200px;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 992px) {
  .GalleryGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .GalleryGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
}
@media (max-width: 480px) {
  .GalleryGrid {
    grid-template-columns: 1fr;
  }
}
//swiper
// @media (max-width: 1200px) {
//   .swiper {
//     width: 90%;
//     height: 90%;
//   }
// }
// @media (max-width: 992px) {
//   .swiper {
//     width: 80%;
//     height: 80%;
//   }
// }
// @media (max-width: 768px) {
//   .swiper {
//     width: 50%;
//     height: 50%;
//   }
// }
// @media (max-width: 480px) {
//   .swiper {
//     width: 40%;
//     height: 40%;
//   }
// }
//左右键

@media (max-width: 1200px) {
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 3em;
  }
  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
  }
}
@media (max-width: 992px) {
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 2.5em;
  }
    .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
  }
}
@media (max-width: 768px) {
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 2em;
  }
      .swiper-pagination-bullet {
    width: 4px;
    height: 4px;
  }
}
@media (max-width: 480px) {
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 1em;
  }
      .swiper-pagination-bullet {
    width: 3px;
    height: 3px;
  }
}
</style>
