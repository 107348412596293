<template>
  <section id="Promotion">
    <h2>限时福利活动</h2>
    <div class="promotionText">
      <p>
        感谢广大玩家的支持与热爱！为了回馈大家，我们特别推出限时福利活动，赠送珍贵礼包码，助你在《恐龙岛》中更快成长，体验更多精彩内容。
      </p>
      <p>
        立即参与活动，输入下方礼包码，获取专属奖励，开启你的恐龙冒险之旅吧！
      </p>
      <div class="Gift" @click="clickCode">
        礼包码：
        <span id="code" ref="code">DINO2025</span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Promotion",
  data() {
    return {};
  },
  mounted() {},
  methods:{
    clickCode(){        
        let code=this.$refs.code.innerText;
        this.$emit("copyCode",code)    
    }
  }
};
</script>
<style lang="scss" scoped>
#Promotion {
  padding: 100px 50px;
  background: #1a1a1a;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #f05454;
  }
  .promotionText {
    // max-width: 800px;
    background: rgba(0, 0, 0, 0.8);
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.5);
    position: relative;
    p {
      margin-bottom: 20px;
      line-height: 1.6;
      font-size: 1.1em;
      color: #ddd;
    }
    .Gift {
      font-size: 2em;
      color: #ffd700;
      font-weight: bold;
      padding: 15px 25px;
      background: rgba(255, 215, 0, 0.2);
      border-radius: 10px;
      display: inline-block;
      transition: transform 0.3s, background 0.3s;
      cursor: pointer;
      position: relative;
    }
    .Gift:hover {
      transform: scale(1.2);
      background: rgba(255, 215, 0, 0.4);
    }
  }
}
</style>
